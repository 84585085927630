<template>
    <div style="max-width: 300px;margin: 20px auto;" ref="load">
        <el-form :model="ruleForm" :rules="rules" ref="formCheck" label-width="80px" class="login-form">
            <el-form-item prop="name" label="分组名称">
                <el-input placeholder="请输入标签名称" v-model="ruleForm.name"></el-input>
            </el-form-item>
            <div class="flex flex-justify-around" style="padding-top:30px;">
                <div><el-button @click="hide()" style="width: 80px;" type="primary" plain>取消</el-button></div>
                <div><el-button @click="submitForm()" style="width: 80px;" type="primary">提交</el-button></div>
            </div>
        </el-form>
    </div>
</template>
<script>
    import { addAgencyGroup, updateAgencyGroup } from '@/service/white';
    export default {
        props: ['form','popTitle'],
        data() {
            return {
                rules: {
                    name: [
                        { required: true, message: '请输入分组名称', trigger: 'blur' }
                    ],
                },
                ruleForm:{
                    name:null,
                    group_id:null,
                },
            }
        },
        watch:{
            form:{
                handler() {
                    this.ruleForm = this.form;
                },
                deep: true
            }
        },
        created() {
            this.ruleForm = this.form;
            console.log(this.ruleForm);
        },
        mounted() {},
        methods: {
            hide() {
                this.$emit("hide");
            },
            submitForm() {
                this.$refs['formCheck'].validate((valid) => {
                    if (valid) {
                        this.submit();
                    }
                });
            },
            submit() {
                const _loading = this.$loading({
                    fullscreen: true, text: '拼命加载中....',
                    target:this.$refs.load
                });
                if (this.ruleForm.group_id) {
                    updateAgencyGroup(this.ruleForm).then(rst => {
                        this.$emit("success");
                        this.$emit("hide");
                        this.$message.success("修改成功");
                        _loading.close();
                    }).catch(err => {
                        this.$message.error(err.message);
                        _loading.close();
                    });
                } else {
                    addAgencyGroup(this.ruleForm).then(rst => {
                        this.$emit("success");
                        this.$emit("hide");
                        this.$message.success("添加成功");
                        _loading.close();
                    }).catch(err => {
                        this.$message.error(err.message);
                        _loading.close();
                    });
                }
            },
        }
    }
</script>
<style scoped>
    
</style>
