<template>
    <div class="zt-page-content">
        <div class="block" style="height: calc(100% - 40px)">
            <div class="tab">
                <!-- <div class="tab_menu flex-1" style="overflow-x:auto;">
                    <div style="overflow-x:auto;">
                        <div v-for="(item,idx) in tagList" :key="idx" @click="tab(idx)" class="flex menu_item" :class="{'cur':tagIdx == idx}">
                            <span>{{item.name}}</span>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="flex flex-center">
                    <el-button @click="add_tag()" type="primary" size="small">创建分组</el-button>
                </div> -->
                <el-tabs v-model="tagIdx" type="card" @tab-click="tab" style="width:100%">
                    <el-tab-pane
                        v-for="(item,index) in tagList"
                        :key="index"
                        :label="item.name"
                        :name="(index).toString()"
                    >
                        <!-- {{item.name}} -->
                    </el-tab-pane>
                </el-tabs>
            </div>
            <div class="head-btn">
                <el-button @click="add_tag()" type="primary" size="mini">创建渠道分组</el-button>
                <el-button @click="edit_tag()" size="mini">重命名</el-button>
                <!-- <el-button @click="invit_tag()" size="mini">邀请经销商</el-button> -->
                <el-button @click="goto('/mine/factory/white/groupGoods/'+tagId)" size="mini">查看分组商品</el-button>
                <el-button @click="del_tag()" type="danger" size="mini">删除</el-button>
            </div>
            <div class="list" style="height: calc(100% - 170px);overflow-y: auto">
                <el-table :data="agentList" ref="multipleTable" style="width: 100%">
                    <el-table-column prop="id" label="企业名称">
                        <template slot-scope="scope">
                            <div>{{scope.row.sale_comp.name}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="apply_at" label="申请日期">
                        <template slot-scope="scope">
                            <div>{{scope.row.apply_at | moment("YYYY-MM-DD")}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="stat" label="当前状态">
                        <template slot-scope="scope">
                            <div v-if="scope.row.stat==1" style="color:#5074EE;">已申请</div>
                            <div v-if="scope.row.stat==2" style="color:#2BBAAE;">已通过</div>
                            <div v-if="scope.row.stat==3" style="color:#F66F6A;">已拒绝</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="address" label="操作" width="220">
                        <template slot-scope="scope">
                            <el-button v-if="scope.row.stat==1" @click="check(scope.row,2)" type="text" size="small">
                                通过
                            </el-button>
                            <el-button v-if="scope.row.stat==1" @click="check(scope.row,3)" type="text" size="small">
                                不通过
                            </el-button>
                            <el-button v-if="scope.row.stat==3" @click="check(scope.row,5)" type="text" size="small">
                                重新通过
                            </el-button>
                            <el-button v-if="scope.row.stat==2" @click="check(scope.row,4)" type="text" size="small">
                                移出白名单
                            </el-button>
                            <el-button @click="viewZizhi(scope.row.sale_comp.id)" type="text" size="small">
                                查看详情
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div style="padding:12px" class="flex">
                <el-pagination background layout="prev, pager, next" 
                    :current-page.sync="queryOpt.page" 
                    :page-size="queryOpt.pagesize" :total="total">
                </el-pagination>
            </div>
        </div>

        <el-dialog :title="popTitle" :visible.sync="popVisible" :destroy-on-close="true">
            <EditTags :form="ruleForm" :popTitle="popTitle" @success="tag_list" @hide="hide"></EditTags>
        </el-dialog>
        
    </div>
</template>
<script>
    import { listAgencyGroup, delAgencyGroup, listAgencyByGroup, statAgency } from '@/service/white';
    import EditTags from './EditTags'
    export default {
        components: {
            EditTags
        },
        data() {
            return {
                popVisible: false,
                popTitle: '',
                // 代理商分组
                tagList:[],
                tagIdx: '0',
                tagId:null,
                ruleForm:{},
                // 分组下的代理商
                queryOpt:{
                    group_id: null,
                    page:1,
                    pagesize:15,
                },
                total:0,
                agentList:[],
            }
        },
        created() {
            this.tag_list();
        },
        mounted() {},
        methods: {
            goto(link) {
                this.$router.push(link);
            },
            viewZizhi(id) {
                window.open('/zizhi/view/'+id);
            },
            // 选项卡
            tab(event) {
                console.log(event.name);
                this.tagIdx = event.name;
                this.tagId = this.tagList[parseInt(this.tagIdx)].id;
                this.queryOpt.group_id = this.tagId;
                this.queryOpt.page = 1;
                this.agent_list();
            },
            tag_list() {
                listAgencyGroup({comp_id:this.$store.state.loginUser.comp.id}).then(rst => {
                    if (rst && rst.length>0) {
                        this.tagList = rst;
                        this.tagId = rst[parseInt(this.tagIdx)].id;
                        this.queryOpt.group_id = this.tagId;
                        this.agent_list();
                    } else {
                        this.tagList = [];
                    }
                    console.log("tagList",this.tagList);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            add_tag() {
                this.popTitle = "新增代理商分组";
                this.popVisible = true;
                this.ruleForm = {
                    name:"",
                }
            },
            edit_tag() {
                this.popTitle = "编辑代理商分组";
                this.popVisible = true;
                this.ruleForm = {
                    group_id:this.tagList[this.tagIdx].id,
                    name:this.tagList[this.tagIdx].name,
                }
                console.log(this.ruleForm);
            },
            del_tag() {
                this.$confirm(`确认要删除吗?`, '温馨提示', {
                    confirmButtonText: '确定',cancelButtonText: '取消',type: 'warning'
                }).then(() => {
                    delAgencyGroup(this.tagId).then(rst => {
                        if (parseInt(this.tagIdx)>0) {
                            this.tagIdx = (parseInt(this.tagIdx) - 1).toString();
                            this.tagId = this.tagList[this.tagIdx].id;
                            this.queryOpt.group_id = this.tagId;
                        }
                        this.tag_list();
                        this.$message.success('删除成功!');
                    }).catch(err => {
                        this.$message.error(err.message);
                    });
                });
            },
            hide() {
                this.popVisible=false;
            },
            agent_list() {
                listAgencyByGroup(this.queryOpt).then(rst => {
                    this.agentList = rst;
                    console.log("agentList",this.agentList);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            // 审核企业是否通过
            check(item,type) { // 状态，1已申请，2已通过，3已拒绝
                let text = null;
                if (type == 2) {
                    text = "确认要通过审核吗？"
                } else if (type == 3) {
                    text = "确认要拒绝审核吗？"
                } else if (type == 4) {
                    text = "确认要移出白名单吗？"
                } else if (type == 5) {
                    text = "确认要重新通过审核吗？"
                }
                this.$confirm(text, '温馨提示', {
                    confirmButtonText: '确定',cancelButtonText: '取消',type: 'warning'
                }).then(() => {
                    if (type == 2) {
                        statAgency({id:item.id,stat:type}).then(rst => {
                            this.agent_list();
                            this.$message.success('已通过审核');
                        }).catch(err => {
                            console.log(err);
                            this.$message.error(err.message);
                        });
                    } else if (type == 3) {
                        statAgency({id:item.id,stat:type}).then(rst => {
                            this.agent_list();
                            this.$message.success('已拒绝');
                        }).catch(err => {
                            console.log(err);
                            this.$message.error(err.message);
                        });
                    } else if (type == 4) {
                        statAgency({id:item.id,stat:3}).then(rst => {
                            this.agent_list();
                            this.$message.success('已移出白名单');
                        }).catch(err => {
                            console.log(err);
                            this.$message.error(err.message);
                        });
                    } else if (type == 5) {
                        statAgency({id:item.id,stat:2}).then(rst => {
                            this.agent_list();
                            this.$message.success('已重新通过审核');
                        }).catch(err => {
                            console.log(err);
                            this.$message.error(err.message);
                        });
                    }
                });
                
            }
        }
    }
</script>
<style scoped src="../../style.css"></style>
<style scoped>
    div>>> .el-dialog {
        width: 524px;
    }
    .tab>>> .el-tabs__header {
        margin: 0;
    }
    /*----选项卡----*/
    .tab {
        width: calc(100% - 40px);
        margin: 0 auto;
        padding:10px 0;
        /* height: 48px; */
    }
    .tab_menu {
        height: 48px;
    }
    .tab_menu .menu_item{
        display: inline-block;
        vertical-align: top;
        padding: 0 20px;
        height: 48px;
        text-align: center;
        line-height: 48px;
        margin-right:10px;
        cursor: pointer;
        font-size: 15px;
        color: #808080;
        position: relative;
    }
    .tab_menu .menu_item.cur {
        color: #808080;
        background: #F5F5F5;
    }
    .tab_menu .menu_item.cur::before {
        content: '';
        width: 100%;
        height: 2px;
        border-top: 2px solid #4D4D4D;
        position: absolute;
        top: 0; left: 0;
    }
    .head-btn {
        text-align: right;
        padding: 11px 20px;
        margin: 0 20px;
        border: 1px solid #E6E6E6;
    }
    .list {
        margin: 0 20px;
        border: 1px solid #EBEEF5;
    }
</style>